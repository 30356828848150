<script setup lang="ts">
import type { Agency } from '@prisma/client';
import type { ClientWithDetailsE } from '~/types/clients';

const props = withDefaults(
  defineProps<{
    client?: ClientWithDetailsE;
    agency?: Agency;
    isChecked?: boolean;
    showCheckBox?: boolean;
    loading?: boolean;
    notifications?: number;
  }>(),
  {
    client: undefined,
    agency: undefined,
    isChecked: false,
    showCheckBox: true,
    loading: false,
    notifications: 0,
  }
);

const initials = computed(() => {
  if (!props.client) {
    if (props.agency) return props.agency.name.substring(0, 2).toUpperCase();
    return '';
  }
  if (props.client.name.length < 3) return props.client.name.toUpperCase();
  return props.client.name.substring(0, 2).toUpperCase();
});
</script>

<template>
  <div
    class="cursor-pointer transition-all duration-300 ease-hub-ease w-full h-[60px] px-3.5 py-3 bg-ht-cc-rc-bg-fill rounded-[14px] border border-ht-border-1 justify-between items-center inline-flex hover:bg-ht-cc-rc-bg-hover"
  >
    <div class="max-w-[90%] grow items-center gap-3.5 inline-flex">
      <USkeleton v-if="loading" class="cc-thumb w-9 h-9"></USkeleton>
      <div v-else-if="client || agency" class="cc-thumb w-9 h-9">
        <img
          v-if="client?.avatarUrl || agency?.logoUrl"
          class="object-cover object-center"
          :src="imageAssetSrc(client?.avatarUrl ?? agency!.logoUrl!)"
        />
        <span v-else class="px-1.5 py-[9px]">{{ initials }}</span>
      </div>
      <USkeleton v-if="loading" class="grow h-4"></USkeleton>
      <div v-else class="justify-start items-center gap-1.5 inline-flex">
        <span
          class="text-ht-monochrome-bw text-sm font-medium leading-[16.80px] whitespace-nowrap truncate"
        >
          {{ client?.name ?? agency?.name ?? 'N/A' }}
        </span>
      </div>
    </div>

    <div
      v-if="!loading"
      class="justify-end items-center gap-6 inline-flex md:hidden"
    >
      <div
        v-if="showCheckBox"
        class="w-[18px] h-[18px] p-0.5 opacity-60 rounded-[5px] border border-ht-border-3"
      ></div>
      <div
        v-if="notifications"
        class="h-9 pl-3 pr-4 py-1.5 unread-bg rounded-[11px] justify-start items-center gap-1.5 inline-flex"
      >
        <HIcon icon="ChatLines" class="w-4 h-4 text-ht-text-monochrome-bw" />

        <span
          class="text-ht-text-monochrome-bw whitespace-nowrap text-xs font-normal"
          >{{ notifications }}</span
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cc-thumb {
  @apply w-9 h-9 bg-ht-btn-gray rounded-lg border border-ht-border-2 flex-col justify-center items-center gap-2.5 inline-flex overflow-hidden;
  @apply text-ht-text-gray-1 text-sm font-semibold leading-[1];
}
</style>
